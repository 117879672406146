/*
CUSTOM COLOUR VARIABLES
Add key: value pairs for custom colours below.
A class of the colour name (key) will be created for background-color.
A class appended with '-text' will be created for text-color.
*/
$custom_colors: (
    liver-purple: #5C2D91,
	liver-grey: #4F4C4A
);
$liver-purple: map-get($custom_colors, "liver-purple");
$liver-grey: map-get($custom_colors, "liver-grey");

@import "@materializecss/materialize/sass/components/color-variables";
$primary-color: $liver-grey;
$secondary-color: $liver-purple;
$link-color: $liver-purple;
$card-link-color: $liver-purple;

/*
DO NOT REMOVE - AUTO CLASS CREATION
*/
@each $color_name, $color_value in $custom_colors {
    .#{$color_name} {
        background-color: $color_value !important;
    }
    .#{$color_name}-text {
        color: $color_value !important;
    }
}
/*
END AUTO CLASS CREATION
*/