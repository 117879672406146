@import './helper/variables';
@import '@materializecss/materialize/sass/materialize.scss';
@import '../../mvp/css/fixes/materialize.scss';
@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';
/* Remove fancybox fix when libsass bug with env() is resolved */
@import '../../mvp/css/fixes/jquery.fancybox.css';
/*@import '~@fancyapps/fancybox';*/

/* Custom styling - you can use materialize helper variables here */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
body {
    @extend .white;
    @extend .liver-grey-text;
    font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4, .h1_dummy, .h2_dummy, .h3_dummy, .h4_dummy {
    @extend .liver-purple-text;
}

h1 { font-size: 2em; margin-bottom: 0.5em; }
h2, .h2_dummy { font-size: 1.5em; }
h3, .h3_dummy { font-size: 1.25em; }
h4, .h4_dummy { font-size: 0.875em; }
h5, .h5_dummy { font-size: 0.75em; }
h6, .h6_dummy { font-size: 0.7em; }
h1:first-of-type {
    margin-top: 0;
}
.mvp_body h1:first-of-type {
    text-align: center;
}
a:not(.btn):not(.sidenav-trigger):hover {
    color: #492474;
}

header > .inner {
    margin-top: 1.5em;
}
header > .inner > .row {
    position: relative;
}
header .header_left, header .header_right {
    display: inline-block;
    vertical-align: top;
}
header .header_left {
    width: 208px;
    overflow-x: visible;
    margin-left: 0.75rem;
}
header img.header_logo {
    min-width: 214px;
    max-width: 214px;
    width: 100%;
}
header .header_right {
    width: calc(100% - 208px - 1.5rem);
    margin-right: 0.75rem;
    text-align: right;
}
header .branch_item {
    display: inline-block;
    vertical-align: top;
}
header a.branch_item {
    font-size: 2em;
    margin-right: 1em;
    i {
        font-size: 1.5em;
    }
}
header .social-icons {
    white-space: nowrap;
    margin-top: 3px;
    svg {
        width: 42px !important;
        height: 42px !important;
        margin-left: 0.25em;
    }
}
header .mvp_menu {
    position: absolute;
    width: calc(100% - 208px - 1.5rem);
    top: 167px;
    nav {
        height: 38px;
        line-height: 38px;
        box-shadow: none !important;
        .sidenav-trigger {
            height: 38px !important;
            line-height: 38px !important;
            float: right;
            i {
                height: 38px;
                line-height: 38px;
            }
        }
    }
}

menu nav ul {
    max-width: 1280px;
    margin: 0 auto;
}
menu nav ul:not(.dropdown-content) > li {
    font-size: 0.875em;
    > a {
        width: calc(100% / 6);
        font-size: 1.25em;
        color: #fff !important;
    }
    > a:hover {
        @extend .liver-purple;
    }
}
menu nav ul:not(.dropdown-content) > li.js-current-menu > a {
    @extend .liver-purple;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
}
menu nav ul:not(.dropdown-content) > li.js-current-menu:first-child > a {
    border-left: none;
}
menu nav ul.dropdown-content li {
    text-align: center;
}
menu ul.mvp_menu_items:not(.mobile) > li > a i {
    display: none;
}

main.mvp_branch .mvp_body table {
    width: auto;
}

form.mvp_search {
    max-width: 400px;
    margin-left: auto;
}
.input-field input[type=search] {
	height: inherit;
	padding-left: 4rem;
	padding-right: 4rem;
	width: calc(100% - 8rem);
	border-bottom: none;
}
.input-field input[type=search] ~ .material-icons {
	position: absolute;
	top: 0;
	right: 0;
	color: transparent;
	cursor: pointer;
	font-size: 2rem;
	-webkit-transition: .3s color;
	transition: .3s color;
}
.input-field input[type=search]:focus:not(.browser-default)+label i,
.input-field input[type=search]:focus:not(.browser-default) ~ .material-icons {
	color: $primary-color;
}

main {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}
main .inner {
    max-width: 1280px;
    margin: 0 auto;
}
main.home-page {
    h2 {
        text-align: center;
    }
    .enquiry_form {
        text-align: center;
        .input-field {
            @extend .m6, .l3;
        }
    }
}

.js-infinitegrid .ig-item {
  overflow: hidden;
}

.social-icons a {
  margin-right: 0.75em;
}
.social-icons a:last-of-type {
  margin-right: 0;
}
.social-icons svg path {
	fill: $link-color;
}

.services-highlight {
    text-align: center;
}
.services-highlight .mvp_body svg {
    width: auto;
    height: 50px;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 1em;
}
.services-highlight .mvp_body ul {
    margin-left: 0;
}
.services-highlight .mvp_body li {
    @extend .chip;
	display: inline-block !important;
    float: none !important;
    height: auto;
	min-height: 36px;
	background-color: transparent;
    border: 2px solid $liver-grey;
	margin-left: 5px;
	margin-top: 5px;
	text-align: center;
    font-size: 1.125em;
    white-space: nowrap;
}
.services-highlight .call-to-action {
    margin-bottom: 2em;
}

.page-footer {
    background-color: transparent;
    @extend .liver-grey-text;
}
footer .social-icons {
    margin-top: 0.5em;
}
footer a { @extend .liver-purple-text }
footer a:hover { color: #492474; }
footer > .inner .col:not(.mvp_body) {
    text-align: center;
}
footer .mvp_connected.mvp_menu li.submenu {
	display: inline-block;
    vertical-align: top;
	width: 50%;
}
footer .footer-copyright {
    font-size: 0.875em;
    @extend .liver-grey-text;
}
footer .footer-copyright span {
    display: inline-block;
    margin-left: 1em;
    margin-right: 1em;
}
footer .footer-copyright .legal {
	float: left;
}
footer .footer-copyright .web-developer {
	float: right;
}

@media #{$small-and-down} {
    /* small screens and down */
    .inner {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    header .branch_item:first-child {
        font-size: 1.5em;
        margin-left: 0;
        i {
            font-size: 1.5em;
        }
    }
	menu nav {
        .brand-logo {
		    line-height: 56px;
	    }
        .sidenav-trigger {
            margin: 0 9px;
            i.left {
                margin-right: 6px;
            }
        }
    }
    .services-highlight .mvp_body li {
        font-size: 0.75em;
    }
    footer .mvp_connected.mvp_menu li.submenu {
        width: 100%;
    }
    footer .footer-copyright {
        text-align: center;
        .legal, .web-developer {
            float: none;
        }
    }
}
@media #{$medium-and-up} {
    /* medium screens and larger */
    .services-highlight:nth-child(3), 
    .services-highlight:nth-child(5) {
        clear: both;
    }
    main.services .mvp_connected {
        > .mvp_content_block:nth-child(3), 
        > .mvp_content_block:nth-child(5), 
        > .mvp_content_block:nth-child(7), 
        > .mvp_content_block:nth-child(9) {
            clear: both;
        }
    }
}
@media #{$medium-and-down} {
    /* medium screens and down */
    .inner {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .page-footer {
        .mvp_body {
            text-align: center;
        }
        .h3_dummy {
            margin-top: 1.5em;
        }
    }
}
@media #{$large-and-up} {
    /* large screens and up */
    .services-highlight:nth-child(3), 
    .services-highlight:nth-child(5) {
        clear: none;
    }
    .services-highlight:nth-child(4),
    .services-highlight:nth-child(7), 
    .services-highlight:nth-child(10) {
        clear: both;
    }
}
@media #{$extra-large-and-up} {
    /* extra large screens and up */
}
@media only screen and (min-width: 1600px) {
	/* xxl screens and up */
}
/* Custom screens */
@media only screen and (max-width: 760px) {
    header .mvp_search {
        display: none;
    }
    header .branch_item:first-child {
        margin-right: 0;
        margin-left: 1em;
    }
}
@media only screen and (max-width: 524px) {
    header .branch_item:first-child {
        line-height: 1.1em;
        i {
            display: none;
        }
    }
}
@media only screen and (max-width: 455px) {
    header .social-icons {
        display: none;
    }
    header .branch_item:first-child i {
        display: block;
        float: left !important;
        clear: both;
        margin-right: 0px;
    }
}